.App {
  text-align: center;
}

.main {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.game-board{ 
  display: grid;
  grid-template-columns: 70px 70px 70px 70px 70px; 
  grid-template-rows: 70px 70px 70px 70px 70px 70px 20px;
  justify-content: center;
  position: absolute;
  top: 90px;
}

.box {
  border: 2px solid gray;
  margin-bottom: 5px;
  margin-left: 5px;
  text-align: center;
  font-size: 40px;
 }

.message {
  position: absolute;
  top: 30px;
  font-size: 20px;
  display: inline-block;
  text-align: center;
}

#row1 {
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: center;
}

#row2 {
  position: absolute;
  bottom: 70px;
  display: flex;
  justify-content: center;
}

#row3 {
  position: absolute;
  bottom: 125px;
  display: flex;
  justify-content: center;
}

#buttonStyle {
  margin-left: 3px;
  margin-bottom: 5px;
}
